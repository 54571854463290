<template>
  <div>
    <section v-if="isLoading" class="mt-4">
      <v-skeleton-loader
        class="mx-auto"
        type="heading"
      ></v-skeleton-loader>
      <v-skeleton-loader
        class="mx-auto"
        type="list-item-two-line, card, list-item-two-line"
      ></v-skeleton-loader>
    </section>
    <section-error
      v-else-if="isError"
      @fetchData="$emit('fetchData')"
    />
    <section v-else>
      <route-info-table :routes="item.routes"/>
      <h5 class="mt-4 mb-4">{{$_strings.order.SHIPPER_INFO}}</h5>
      <v-row justify="center" class="pa-3">
        <v-expansion-panels v-model="panels" accordion multiple class="rounded">
          <v-expansion-panel
            v-for="(shipment,index) in item.shipmentResponse"
            :key="index"
            class="grey lighten-5 mt-0"
          >
            <v-expansion-panel-header>
              <v-row>
                <v-col class="d-flex align-center pl-0">
                  <input
                    type="text"
                    :value="shipment.orderNumber"
                    readonly
                    style="outline: none;font-weight: bold;"
                  />
                </v-col>
                <v-col cols="12" sm="4" class="d-flex justify-sm-end pb-2">
                  <v-chip
                    :color="chipColor(shipment.orderStatus).color"
                    :class="chipColor(shipment.orderStatus).class"
                    label
                  >
                    {{shipment.orderStatus}}
                  </v-chip>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="white mt-0">
              <div style="margin-left:-12px">
                <shipper-details :shipment="shipment" />
                <v-row
                  v-if="isShowBtnCalculate(shipment)"
                  class="mt-2 d-flex justify-space-between"
                >
                  <v-col cols="12" sm="auto">
                    <h3>{{$_strings.order.LABEL_TOTAL_BILL}}</h3>
                  </v-col>
                  <v-col cols="12" sm="auto">
                    <v-btn
                      color="primary"
                      :disabled="isDisableButtonCalculateCost(shipment)"
                      @click="showDialogCalculateCost(shipment, index)"
                    >
                      {{$_strings.order.CALCULATE}}
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row class="mt-2">
                  <v-col cols="12" sm="4">
                    <order-status :orderHistories="shipment.orderHistories" />
                  </v-col>
                  <v-col cols="12" sm="8">
                    <v-card>
                      <v-card-title>
                        <h5 v-if="shipment.documents">{{$_strings.order.DOCUMENTATION}}</h5>
                        <v-spacer></v-spacer>
                        <v-checkbox
                          dense
                          disabled
                          hide-details
                          class="ma-0"
                          v-model="shipment.isDocumentComplete"
                        >
                          <template v-slot:label>
                            <span class="caption">{{$_strings.order.COMPLETED_DOCUMENT}}</span>
                          </template>
                        </v-checkbox>
                      </v-card-title>
                      <v-card-text>
                        <documents
                          :shipment="shipment"
                          :itemsRouteInfo="shipment.routes"
                          :isPageOrder="isPageOrder"
                          @fetchShipmentDetail="$emit('fetchData')"
                        ></documents>
                        <v-divider class="mt-6 mb-2"></v-divider>
                        <Map :shipment="shipment"/>
                      </v-card-text>
                      <v-card-actions v-if="isShowBtnOrderUpdate">
                        <order-update-button :routes="routes" :shipment="shipment" />
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
      <DialogCalculateCost
        ref="dialogCalulateCost"
        @fetchShipmentDetail="$emit('fetchData')"
      />
    </section>
  </div>
</template>

<script>

const RouteInfoTable = () => import(/* webpackChunkName: "RouteInfoTable" */ './RouteInfoTable.vue');
const SectionError = () => import(/* webpackChunkName: "SectionError" */ './SectionError.vue');
const ShipperDetails = () => import(/* webpackChunkName: "ShipperDetails" */ './ShipperDetails.vue');
const Documents = () => import(/* webpackChunkName: "Documents" */ './Documents.vue');
const Map = () => import(/* webpackChunkName: "Map" */ './Map.vue');
const OrderStatus = () => import(/* webpackChunkName: "OrderStatus" */ './OrderStatus.vue');
const OrderUpdateButton = () => import(/* webpackChunkName: "OrderUpdateButton" */ './OrderUpdateButton.vue');
const DialogCalculateCost = () => import(/* webpackChunkName: "DialogCalculateCost" */ '../Dialog/CalculateCost.vue');

export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    isError: {
      type: Boolean,
      default: false,
    },
    canUpdateStatus: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isPageOrder: {
      type: Boolean,
      default: false,
    },
    itemSequence: {
      type: Object,
      default: () => {},
    },
    routeForUpdate: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    SectionError,
    RouteInfoTable,
    ShipperDetails,
    OrderStatus,
    Documents,
    Map,
    OrderUpdateButton,
    DialogCalculateCost,
  },
  mounted() {
    if (this.item.shipmentResponse) {
      this.panels = this.item.shipmentResponse.map((_s, i) => i);
    }
  },
  watch: {
    item: {
      handler(newVal) {
        this.panels = newVal.shipmentResponse.map((_s, i) => i);
      },
      deep: true,
    },
  },
  data() {
    return {
      panels: [0],
    };
  },
  computed: {
    myUserInfo() {
      return this.$store.state.user.myUserInfo;
    },
    routes() {
      return this.item.routes || [];
    },
    isShowBtnOrderUpdate() {
      const showInPageStatus = ['UNCONFIRMED', 'PROCESS'];
      return this.userAccess.canUpdate
      && showInPageStatus.includes(this.$route.query.status.toUpperCase())
      && this.isPageOrder;
    },
  },
  methods: {
    chipColor(status = '') {
      switch (status.toLowerCase()) {
        case 'waktu habis':
        case 'kedaluwarsa':
        case 'dibatalkan': return {
          color: 'red',
          class: 'white--text',
        };
        default: return {
          color: 'cyan lighten-5',
          class: 'cyan--text',
        };
      }
    },
    isShowBtnCalculate(shipment) {
      const { roleName = '' } = this.myUserInfo;
      const rolesNamesAreCannotCalculate = ['DATA_ENTRY'];
      const routeStatus = this.$route.query.status.toUpperCase();
      const statusIncomplete = ['kedaluwarsa', 'dibatalkan', 'waktu habis'];
      if (rolesNamesAreCannotCalculate.includes(roleName.toUpperCase())) return false;
      return routeStatus === 'FINISHED'
      && this.userAccess.canUpdate
      && !shipment.isCalculated
      && !statusIncomplete.includes(shipment.orderStatus.toLowerCase());
    },
    isDisableButtonCalculateCost(shipment) {
      if (typeof shipment.tonnage === 'number'
      || typeof shipment.carton === 'number'
      || typeof shipment.cubic === 'number') {
        return false;
      }
      return true;
    },
    showDialogCalculateCost(shipment, index) {
      this.$refs.dialogCalulateCost.shipmentOrder = index + 1;
      this.$refs.dialogCalulateCost.orderSelected = shipment;
      this.$refs.dialogCalulateCost.dialog = true;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-expansion-panel-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }
  .v-expansion-panel::before {
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.2);

  }
  .v-expansion-panel--active > .v-expansion-panel-header {
    min-height: 0px;
  }
}
</style>
